import AttendanceType from "../type/attendanceType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  attendanceList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const AttendanceReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AttendanceType.ADD_ATTENDANCE:
      return {
        ...state,
      };

    case AttendanceType.GET_ATTENDANCE:
      return {
        ...state,
        attendanceList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case AttendanceType.UPDATE_ATTENDANCE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default AttendanceReducers;
