import { useCallback, useEffect, useState } from "react";
import Weblayout from "../../layout/Weblayout";
import { useDispatch, useSelector } from "react-redux";
import LeaveAction from "../../stores/action/leaveAction";
import moment from "moment";
import { RootState } from "../../stores";

function LeaveRequest() {
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [leaveType, setLeaveType] = useState<any>();
  const [reason, setReason] = useState<any>();
  const [search, setSearch] = useState<any>();
  const [limit, setLimit] = useState<any>(10);
  const [page, setPage] = useState<any>(1);
  const dispatch = useDispatch<any>();

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await dispatch(
        LeaveAction.sendLeaveRequest({
          start_date: startDate,
          end_date: endDate,
          leave_type: leaveType,
          reason: reason,
        })
      );
      getLeaveRequestData();
      setStartDate("");
      setEndDate("");
      setLeaveType("");
      setReason("");
    } catch (error) {
      console.log(error);
    }
  };

  const { leaveRequestList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.leaveRequest);

  const maxPagesToShow = 5; // Maximum number of page links to show
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getLeaveRequestData = useCallback(async () => {
    try {
      await dispatch(
        LeaveAction.getLeaveRequest({
          search: search,
          limit: limit,
          page: page,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [search, limit, page]);

  useEffect(() => {
    getLeaveRequestData();
  }, [getLeaveRequestData, search, limit, page]);

  return (
    <Weblayout pageName={"LeaveRequest"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ul className="breadcrumb breadcrumb-style mb-0">
                  <li className="breadcrumb-item">
                    <h4 className="page-title">Send Leave Request</h4>
                  </li>
                  <li className="breadcrumb-item bcrumb-1">
                    <a href="/dashboard">
                      <i className="fas fa-home"></i> Home
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Send Leave Request</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card card-body">
            <form onSubmit={handelSubmit}>
              <div className="row">
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">
                      End Date <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label className="form-label">
                      Leave Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      value={leaveType}
                      onChange={(e) => setLeaveType(e.target.value)}>
                      <option value=""> Select Leave Type</option>
                      <option value="Sick"> Sick</option>
                      <option value="Sick"> Sick</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label">
                      Reason <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Reason"
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="form-group">
                    <button type="submit" className="btn btn-success mt-4">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div
              className=" mb-2 d-flex
              justify-content-between
              align-items-end">
              <div className="col-md-3">
                <label className="mb-2">Show Entries</label>
                <select
                  onChange={(e) => setLimit(e.target.value)}
                  id="defaultSelect"
                  className="form-select">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <div className="input-group input-group-merge mb-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table
                id="basicTable"
                className="table table-hover table-checkable order-column contact_list">
                <thead>
                  <tr>
                    <th className="center">#</th>
                    <th className="center"> Start Date</th>
                    <th className="center"> End Date</th>
                    <th className="center"> Days</th>
                    <th className="center"> Leave Type</th>
                    <th className="center"> Reason</th>
                    <th className="center"> Status</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveRequestList?.length > 0 ? (
                    leaveRequestList.map((dataList: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{dataList.start_date}</td>
                          <td>{dataList.end_date}</td>
                          <td>{dataList.days}</td>
                          <td>{dataList.leave_type}</td>
                          <td>{dataList.reason}</td>
                          <td>
                            {dataList.status === "PENDING" ? (
                              <a
                                href="javascript:void(0);"
                                className="label label-warning rounded-0">
                                {dataList.status}
                              </a>
                            ) : dataList.status === "APPROVED" ? (
                              <a
                                href="javascript:void(0);"
                                className="label label-success rounded-0">
                                {dataList.status}
                              </a>
                            ) : dataList.status === "REJECTED" ? (
                              <a
                                href="javascript:void(0);"
                                className="label label-danger rounded-0">
                                {dataList.status}
                              </a>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={10}>No matching records found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {leaveRequestList?.length > 0 ? (
              <div className="row mt-2 justify-content-between">
                <div className="col-md-auto me-auto ">
                  <div className="dt-info text-dark">
                    Showing {from} to {to} of {totalData} entries
                  </div>
                </div>
                <div className="col-md-auto ms-auto ">
                  <div className="dt-paging paging_full_numbers">
                    <ul className="pagination">
                      <li
                        className="dt-paging-button page-item disabled"
                        onClick={() => setPage(1)}>
                        <a
                          className="page-link first"
                          aria-controls="tableGroup"
                          aria-disabled="true"
                          aria-label="First"
                          data-dt-idx="first">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li
                        className={`dt-paging-button page-item prev ${
                          page === 1 ? "disabled" : ""
                        }`}
                        onClick={() =>
                          page === 1 ? "" : setPage(currentPage - 1)
                        }>
                        <a
                          className="page-link previous"
                          aria-controls="tableGroup"
                          aria-disabled="true"
                          aria-label="Previous"
                          data-dt-idx="previous">
                          <span aria-hidden="true">&lt;</span>
                        </a>
                      </li>
                      {pagination.map((p) => {
                        return (
                          <li
                            className={`dt-paging-button page-item ${
                              currentPage === p ? "active" : ""
                            }`}
                            onClick={() => setPage(p)}>
                            <a
                              className="page-link"
                              href="javascript:void(0);"
                              aria-controls="tableGroup"
                              aria-current="page"
                              data-dt-idx="0">
                              {p}
                            </a>
                          </li>
                        );
                      })}

                      <li
                        className={`dt-paging-button page-item next ${
                          lastPage === page ? "disabled" : ""
                        }`}
                        onClick={() =>
                          lastPage === page ? "" : setPage(currentPage + 1)
                        }>
                        <a
                          href="javascript:void(0);"
                          className="page-link next"
                          aria-controls="tableGroup"
                          aria-label="Next"
                          data-dt-idx="next">
                          <span aria-hidden="true">&gt;</span>
                        </a>
                      </li>
                      <li
                        className="dt-paging-button page-item"
                        onClick={() => setPage(lastPage)}>
                        <a
                          href="javascript:void(0);"
                          className="page-link last"
                          aria-controls="tableGroup"
                          aria-label="Last"
                          data-dt-idx="last">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default LeaveRequest;
