import { EMPLOYEE_TOKEN, EMPLOYEE_ID } from "../../utils/Constants";
import AuthType, { User } from "../type/authType";

export interface AuthState {
  user: User | null;
  authenticated: boolean;
  token: any;
  userData: any;
}

export interface ReducerAction {
  type: string;
  payload?: any;
  token?: any;
}

const INITIAL_STATE: AuthState = {
  user: null,
  authenticated: false,
  token: null,
  userData: null,
};

const AuthReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AuthType.EMPLOYEE_LOGIN:
      localStorage.setItem(EMPLOYEE_TOKEN, JSON.stringify(action.token));
      localStorage.setItem(EMPLOYEE_ID, JSON.stringify(action.payload.id));
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        token: action.token,
      };

    case AuthType.RESET_AUTH:
      localStorage.removeItem(EMPLOYEE_TOKEN);
      localStorage.removeItem(EMPLOYEE_ID);
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default AuthReducers;
