import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import AuthType, { LogIn, LogOut } from "../type/authType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import toast from "react-hot-toast";

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AuthAction = {
  logIn:
    (data: LogIn): ThunkAction<void, RootState, null, Action> =>
    async (disptach) => {
      try {
        const user: any = await axios.post(BASE_URL + "login", {
          email: data.email,
          password: data.password,
        });

        if (user.data.status) {
          const payload = {
            id: user.data.data.employee.id,
            name: user.data.data.employee.name,
            email: user.data.data.employee.email,
            mobile: user.data.data.employee.mobile,
          };

          disptach({
            type: AuthType.EMPLOYEE_LOGIN,
            payload: payload,
            token: user.data.data.token,
          });
        } else {
          toast.error("Invalid Credentials");
        }
      } catch (error: any) {
        console.error(error);
      }
    },

  logOut:
    (data: LogOut): ThunkAction<void, RootState, null, Action> =>
    async (disptach) => {
      try {
        const result: any = await axios.get(BASE_URL + "logout", {
          headers: {
            Authorization: "Bearer " + data.tokens.replaceAll('"', ""),
          },
        });
        if (result.data.status) {
          disptach({
            type: AuthType.RESET_AUTH,
          });
        }
      } catch (error: any) {
        console.error("Error");
      }
    },
};

export default AuthAction;
