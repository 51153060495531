import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYEE_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import LeaveType, {
  GetLeaveRequest,
  SendLeaveRequest,
} from "../type/leaveType";

interface Action {
  type: string;
  payload?: any;
}

const LeaveAction = {
  sendLeaveRequest:
    (data: SendLeaveRequest): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "leave/add",
          {
            start_date: data.start_date,
            end_date: data.end_date,
            leave_type: data.leave_type,
            reason: data.reason,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: LeaveType.SEND_LEAVE_REQUEST,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },

  getLeaveRequest:
    (data: GetLeaveRequest): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "leave/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: LeaveType.GET_LEAVE_REQUEST,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        console.log(error.message);
      }
    },
};

export default LeaveAction;
