import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYEE_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import AttendanceType, {
  AddAttendance,
  GetAttendance,
  UpdateAttendance,
} from "../type/attendanceType";

interface Action {
  type: string;
  payload?: any;
}

const AttendanceAction = {
  addAttendance:
    (data: AddAttendance): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "attendance/add",
          {
            date: data.date,
            in_time: data.in_time,
            out_time: data.out_time,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: AttendanceType.ADD_ATTENDANCE,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },

  getAttendance:
    (data: GetAttendance): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "attendance/get",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        dispatch({
          type: AttendanceType.GET_ATTENDANCE,
          payload: {
            data: result.data.data.data,
            currentPage: result.data.data.current_page,
            lastPage: result.data.data.last_page,
            totalData: result.data.data.total,
            from: result.data.data.from,
            to: result.data.data.to,
          },
        });
      } catch (error: any) {
        console.log(error.message);
      }
    },

  updateAttendance:
    (data: UpdateAttendance): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem(EMPLOYEE_TOKEN);
        const result: any = await axios.post(
          BASE_URL + "attendance/update",
          {
            id: data.id,
            date: data.date,
            in_time: data.in_time,
            out_time: data.out_time,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        dispatch({
          type: AttendanceType.UPDATE_ATTENDANCE,
        });
        if (result.data.status === true) {
          toast.success(result.data.msg);
        } else {
          toast.error(result.data.msg);
        }
      } catch (error: any) {
        console.log(error.message);
      }
    },
};

export default AttendanceAction;
