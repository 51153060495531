const LeaveType = {
  SEND_LEAVE_REQUEST: "SEND_LEAVE_REQUEST",
  GET_LEAVE_REQUEST: "GET_LEAVE_REQUEST",
};

export interface SendLeaveRequest {
  start_date: any;
  end_date: any;
  leave_type: string;
  reason: string;
}

export interface GetLeaveRequest {
  search: any;
  limit: number;
  page: number;
}

export default LeaveType;
