import LeaveType from "../type/leaveType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  leaveRequestList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const LeaveReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case LeaveType.SEND_LEAVE_REQUEST:
      return {
        ...state,
      };

    case LeaveType.GET_LEAVE_REQUEST:
      return {
        ...state,
        leaveRequestList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    default:
      return state;
  }
};

export default LeaveReducers;
