import { useCallback, useEffect, useState } from "react";
import InputText from "./InputText";
import ProfileAction from "../../stores/action/profileAction";
import { useDispatch, useSelector } from "react-redux";
import InputFile from "./InputFile";
import { RootState } from "../../stores";

function UpdateProfile({
  modal,
  setModal,
  modalType,
  profileData,
  getProfileData,
}: any) {
  const [loading, setLoading] = useState<any>(false);
  const [profile_img, set_profile_img] = useState<any>();
  const [name, set_name] = useState<any>();
  const [f_name, set_f_name] = useState<any>();
  const [email, set_email] = useState<any>();
  const [mobile, set_mobile] = useState<any>();
  const [state, set_state] = useState<any>();
  const [district, set_district] = useState<any>();
  const [pincode, set_pincode] = useState<any>();
  const [present_address, set_present_address] = useState<any>();
  const [permanent_address, set_permanent_address] = useState<any>();
  const [account_holder_name, set_account_holder_name] = useState<any>();
  const [account_number, set_account_number] = useState<any>();
  const [ifsc_code, set_ifsc_code] = useState<any>();
  const [bank_name, set_bank_name] = useState<any>();
  const [branch_name, set_branch_name] = useState<any>();
  const [pan_img, set_pan_img] = useState<any>();
  const [pan_no, set_pan_no] = useState<any>();
  const [aadhar_img, set_aadhar_img] = useState<any>();
  const [aadhar_no, set_aadhar_no] = useState<any>();
  const dispatch = useDispatch<any>();

  useEffect(() => {
    set_profile_img(profileData?.profile_img);
    set_name(profileData?.name);
    set_f_name(profileData?.f_name);
    set_email(profileData?.email);
    set_mobile(profileData?.mobile);
    set_state(profileData?.state);
    set_district(profileData?.district);
    set_pincode(profileData?.pincode);
    set_present_address(profileData?.present_address);
    set_permanent_address(profileData?.permanent_address);
    set_account_holder_name(profileData?.account_holder_name);
    set_account_number(profileData?.account_number);
    set_ifsc_code(profileData?.ifsc_code);
    set_bank_name(profileData?.bank_name);
    set_branch_name(profileData?.branch_name);
    set_pan_img(profileData?.pan_img);
    set_pan_no(profileData?.pan_no);
    set_aadhar_img(profileData?.aadhar_img);
    set_aadhar_no(profileData?.aadhar_no);
  }, [profileData]);

  const { getStateList } = useSelector((state: RootState) => state.profile);
  const { getDistrictList } = useSelector((state: RootState) => state.profile);

  const handleProfileImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      set_profile_img(file);
    }
  };
  const handlePanImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      set_pan_img(file);
    }
  };

  const handleAadharImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      set_aadhar_img(file);
    }
  };

  const handelSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateProfile({
          profile_img: profile_img,
          name: name,
          f_name: f_name,
          email: email,
          mobile: mobile,
          state: state,
          district: district,
          pincode: pincode,
          present_address: present_address,
          permanent_address: permanent_address,
        })
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.log(error);
    }
  };

  const handelBankDetailsSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateBankDetails({
          account_holder_name: account_holder_name,
          account_number: account_number,
          bank_name: bank_name,
          branch_name: branch_name,
          ifsc_code: ifsc_code,
        })
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.log(error);
    }
  };

  const handelKYCDetailsSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateKYCDetails({
          pan_img: pan_img,
          pan_no: pan_no,
          aadhar_img: aadhar_img,
          aadhar_no: aadhar_no,
        })
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setModal(false);
  };

  const getStateData = useCallback(async () => {
    await dispatch(ProfileAction.getState());
  }, [dispatch]);

  const getDistricData = useCallback(async () => {
    await dispatch(
      ProfileAction.getDistrict({
        state_id: state,
      })
    );
  }, [dispatch, state]);

  useEffect(() => {
    getStateData();
    getDistricData();
  }, [getStateData, getDistricData]);

  console.log(getStateList);

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        {modalType === "profile" ? (
          <form
            className="needs-validation"
            onSubmit={handelSubmit}
            encType="multipart/form-data">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">
                  Update Profile Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <InputFile
                      inputName="Upload Profile Image"
                      handleImageChange={handleProfileImageChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Enter Name"
                      name={name}
                      setName={set_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Father Name"
                      name={f_name}
                      setName={set_f_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Email Id"
                      name={email}
                      setName={set_email}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Mobile No."
                      name={mobile}
                      setName={set_mobile}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Select State</label>
                    <div className="form-group">
                      <select
                        value={state}
                        onChange={(e) => set_state(e.target.value)}
                        className="form-control">
                        <option value="" disabled>
                          Select State
                        </option>
                        {getStateList &&
                          getStateList?.map((stateData: any) => {
                            return (
                              <option key={stateData.id} value={stateData.id}>
                                {stateData.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Select District</label>
                    <div className="form-group">
                      <select
                        value={district}
                        onChange={(e) => set_district(e.target.value)}
                        className="form-control">
                        <option value="" disabled>
                          Select District
                        </option>
                        {getDistrictList &&
                          getDistrictList?.map((districtData: any) => {
                            return (
                              <option
                                key={districtData.id}
                                value={districtData.id}>
                                {districtData.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Pin Code"
                      name={pincode}
                      setName={set_pincode}
                    />
                  </div>
                  <div className="col-md-12">
                    <InputText
                      inputName="Present Address"
                      name={present_address}
                      setName={set_present_address}
                    />
                  </div>
                  <div className="col-md-12">
                    <InputText
                      inputName="Permanent Address"
                      name={permanent_address}
                      setName={set_permanent_address}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  disabled={loading}
                  onClick={closeModal}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4">
                  {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                  Update
                </button>
              </div>
            </div>
          </form>
        ) : modalType === "bank" ? (
          <form
            className="needs-validation"
            onSubmit={handelBankDetailsSubmit}
            encType="multipart/form-data">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">Update Bank Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <InputText
                      inputName="Account Holder Name"
                      name={account_holder_name}
                      setName={set_account_holder_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Account Number"
                      name={account_number}
                      setName={set_account_number}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Bank Name"
                      name={bank_name}
                      setName={set_bank_name}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="IFSC Code"
                      name={ifsc_code}
                      setName={set_ifsc_code}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Branch Name"
                      name={branch_name}
                      setName={set_branch_name}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  disabled={loading}
                  onClick={closeModal}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4">
                  {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                  Update
                </button>
              </div>
            </div>
          </form>
        ) : modalType === "kyc" ? (
          <form
            className="needs-validation"
            onSubmit={handelKYCDetailsSubmit}
            encType="multipart/form-data">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">Update KYC Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6">
                    <InputText
                      inputName="PAN Number"
                      name={pan_no}
                      setName={set_pan_no}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputFile
                      inputName="Upload PAN Image"
                      handleImageChange={handlePanImageChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputText
                      inputName="Aadhar Number"
                      name={aadhar_no}
                      setName={set_aadhar_no}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputFile
                      inputName="Upload Aadhar Image"
                      handleImageChange={handleAadharImageChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  data-bs-dismiss="modal"
                  disabled={loading}
                  onClick={closeModal}>
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4">
                  {loading ? <i className={"fa fa-spinner fa-spin"}></i> : null}
                  Update
                </button>
              </div>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  );
}

export default UpdateProfile;
