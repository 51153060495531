import { Route, Routes } from "react-router-dom";
import Login from "../views/auth/Login";
import Home from "../views/dashboard/Home";
import ViewProfile from "../views/profile/ViewProfile";
import OfferLetter from "../views/offer-letter/OfferLetter";
import MarkAttendance from "../views/attendance/MarkAttendance";
import LeaveRequest from "../views/leave/LeaveRequest";
import ChangePassword from "../views/change-password/ChangePassword";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/profile" element={<ViewProfile />} />
      <Route path="/offer-letter" element={<OfferLetter />} />
      <Route path="/mark-attendance" element={<MarkAttendance />} />
      <Route path="/leave-request" element={<LeaveRequest />} />
      <Route path="/change-password" element={<ChangePassword />} />
    </Routes>
  );
}

export default PageRoutes;
