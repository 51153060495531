function InputText({ inputName, name, setName }: any) {
  return (
    <>
      <label>{inputName}</label>
      <div className="form-group">
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={inputName}></input>
      </div>
    </>
  );
}

export default InputText;
