import Weblayout from "../../layout/Weblayout";

function OfferLetter() {
  return (
    <Weblayout pageName={"OfferLetter"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="offer-letter">
              <header className="offer-letter_header">
                <div className="company-logo">
                  <img
                    src="https://abhhyamsecure.com/assets/img/logo/logo.png"
                    alt="Company Logo"
                  />
                </div>
              </header>
              <div className="header-border">
                <div className="header-border-left"></div>
                <div className="header-border-center"></div>
                <div className="header-border-right"></div>
              </div>
              <main className="offer_letter_main">
                <p>
                  <strong>Date:</strong> <span className="date"></span>
                </p>
                <p>
                  To, <br />
                  <strong>Candidate Name:</strong>
                  <span className="candidate-name"></span>
                </p>
                <p>
                  Dear <span className="candidate-name"></span>,
                </p>

                <p>
                  Thank you for your application and subsequent interviews. We
                  are pleased to offer you the position of
                  <strong>
                    <span className="job-title"></span>
                  </strong>{" "}
                  at our company. You are expected to join on or before
                  <strong>
                    <span className="joining-date"></span>
                  </strong>
                  .
                </p>

                <p>
                  Your employment will take effect from the date of joining. You
                  will be given a detailed professional agreement outlining the
                  terms of your employment. The offered salary for this role is
                  <strong>
                    <span className="salary-details"></span>
                  </strong>{" "}
                  per month.
                </p>

                <p>
                  If you fail to follow the terms and conditions of our company,
                  we reserve the right to cancel this offer at any time.
                </p>

                <p>
                  Please sign and return the duplicate copy of this letter as a
                  token of your acceptance of the above terms and conditions.
                </p>

                <p>
                  We look forward to working with you.
                  <br />
                  With best regards,
                  <br />
                  Sincerely,
                </p>

                <p>
                  <strong>Manager, HR & Operations</strong>
                  <br />
                  <em>Abhhyam Secure</em>
                </p>
              </main>

              <footer className="offer_letter_footer">
                <div className="company-details">
                  <h1>Company Details</h1>
                  <p>Abhhyam Secure Pvt. Ltd.</p>
                  <address>
                    <p>
                      C-7/82, Sector-8, Rohini,
                      <br />
                      New Delhi-110085
                    </p>
                    <p>Phone: +91-9971713967</p>
                    <p>Email: care@abhhyamsecure.com</p>
                    <p>Website: www.abhhyamsecure.com</p>
                  </address>
                </div>
              </footer>
              <div className="footer-border">
                <div className="footer-border-right"></div>
                <div className="footer-border-center"></div>
                <div className="footer-border-left"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Weblayout>
  );
}

export default OfferLetter;
